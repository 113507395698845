import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import XendIcon from "../../icons/xend-icon.png";
import DollarCoin from "../../icons/dollar-coin.svg";
import ProjectBg from "../../icons/project-bg2.png";
import { JoinProjectModal } from "../modals/JoinProjectModal";
import { ReactComponent as Tick } from "../../icons/tick-star.svg";
import { formatNumber } from "../../utils/formatNumber";
import { AirdropConnect } from "../../services/airdrop.service";
import { context } from "../../App";
import { logConsole } from "../../utils/useConsole";
import moment from "moment";

export const SinglePendingProject = ({ airdrop: airdrop_ }) => {
  const navigate = useNavigate();
  const airdropConnect = new AirdropConnect();
  const {
    telegramId,
    triggerNotification,
    updateProject,
    project_id,
    setCoins,
    coins,
    getAirdrops,
    userTap,
    setShowProcessingModal,
  } = useContext(context);
  const [openModal, setOpenModal] = useState(false);
  const [btn, setBtn] = useState(false);

  const goToDetails = (joined, airdrop_id) => {
    if (joined === true) {
      navigate(`/project-details/${airdrop_id}`);
    }
  };

  const airdrop = airdrop_?.airdrop;

  const joinAirdrop = async () => {
    setShowProcessingModal(true);
    try {
      setBtn(true);
      await userTap(coins);
      const { data: response } = await airdropConnect.joinAidrop(
        telegramId,
        airdrop?.airdrop_id
      );
      if (response) {
        setCoins((coin) => coin - airdrop?.cost);
        updateProject({ 
          project_id: project_id, 
          coin: coins - airdrop?.cost 
        });
        console.log({ cost: airdrop.cost, coins })
        await getAirdrops();
        triggerNotification("info", `Joined ${airdrop?.name} Airdrop!`);
        setOpenModal(false);
        setBtn(false);
        localStorage.setItem("hammergames-joined", "true");
      }
      setBtn(false);
    } catch (error) {
      const errorMessage = airdropConnect.handleTheError(error);
      logConsole({ errorMessage }, true);
      triggerNotification("error", errorMessage || "Unable to join airdrop!");
      setBtn(false);
    }
    setShowProcessingModal(false);
  };

  const calculateDate = (startDate) => {
    const daysEnabled = `${moment(startDate).diff(moment(), "days")} ${" "}day${
      moment(startDate).diff(moment(), "days") > 1 ? "s" : ""
    }`;
    const hoursEnabled = `${moment(startDate).diff(moment(), "hours")} ${" "}hour${
      moment(startDate).diff(moment(), "hours") > 1 ? "s" : ""
    }`;
    const useDate =
      moment(airdrop?.startDate).diff(moment(), "days") > 0
        ? daysEnabled
        : hoursEnabled;
    return `Coming in ${useDate}`;
  }

  return (
    <div>
      <div
        className="p-4 bg-cover bg-center bg-no-repeat rounded-[10px] cursor-pointer relative"
        style={{
          backgroundImage: `url('${airdrop.image}')`,
          backgroundColor: "#000000",
          boxShadow: "0px 5px 0px 0px #CACACA40",
        }}
        onClick={() => goToDetails(airdrop?.joined, airdrop?.airdrop_id)}
      >
        <div className="w-full h-full bg-black bg-opacity-45 fixed top-0 bottom-0 left-0 right-0" />
        <div className="flex justify-between items-center w-full mb-4 relative z-30">
          <div className="flex items-center gap-2">
            <div className="relative w-fit">
              <img
                src={airdrop?.logo || XendIcon}
                alt="logo"
                className="w-[1.875rem] h-[1.875rem] rounded-[0.25rem]"
              />
              <Tick className="w-5 h-5 absolute -bottom-1 -right-2" />
            </div>

            <p className="font-semibold text-white text-[0.875rem]">
              {airdrop?.name}
            </p>
          </div>

          <div className="flex items-center gap-1">
            <div className="text-[#774400] text-[0.75rem] h-[30px] min-w-[64px] px-2 rounded-[60px] bg-[#FFF4E5] flex gap-1 items-center justify-center">
              <span>Entry</span>
              <img
                src={DollarCoin}
                alt="coin"
                className="w-[0.94rem] h-[0.94rem]"
              />
              <span>{formatNumber(airdrop?.cost ?? 0)}</span>
            </div>

            <div className="text-[#83C60D] text-[0.75rem] h-[30px] w-[70px] rounded-[60px] bg-[#EEFFD1] flex items-center justify-center">
              <span className="first-letter:uppercase">{airdrop?.status}</span>
            </div>
          </div>
        </div>

        <div className="max-w-[230px] mb-4 relative z-20">
          <p className="font-semibold text-white text-[0.875rem]">
            {airdrop?.description}
          </p>
        </div>

        {/* Footer area */}
        <div className="flex items-center justify-between gap-2 relative z-20">
          <div className="flex items-center gap-2">
            <div>
              <p className="text-[0.625rem] text-white font-normal mb-1">Reward pool</p>
              <div className="flex gap-1 items-center bg-[#010101A3] rounded-[1rem] py-1 px-2 min-h-[30px]">
                <img
                  src={airdrop?.logo || XendIcon}
                  alt="logo"
                  className="w-[1rem] h-[1rem] rounded-[1rem]"
                />
                <span className="text-[0.625rem] text-white">
                  {formatNumber(airdrop?.rewardPoolAmount ?? 0)}{" "}
                  {airdrop?.symbol}
                </span>
              </div>
            </div>

            <div>
              <p className="text-[0.625rem] text-white font-normal mb-1">
                Loot per hour
              </p>
              <div className="flex gap-1 items-center bg-[#010101A3] rounded-[1rem] py-1 px-2 min-h-[30px]">
                <img
                  src={DollarCoin}
                  alt="coin"
                  className="w-[1rem] h-[1rem] rounded-[1rem]"
                />
                <span className="text-[0.625rem] text-white">
                  +{formatNumber(airdrop?.profitPerHour)}
                </span>
              </div>
            </div>
          </div>

          {moment(airdrop?.startDate).diff(moment(), "hours") > 0 ? (
            <button
              onClick={(e) => {
                e.stopPropagation();
                // setOpenModal(true);
              }}
              disabled={false}
              className="bg-[#F28A00] h-[30px] text-[#0D0F18] text-[14px] font-semibold rounded-[30px] px-5"
            >
              <div className={`sub-button first-letter:uppercase`}>
                {calculateDate(airdrop?.startDate)}
              </div>
            </button>
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal(true);
              }}
              disabled={false}
              className="bg-[#83c60d] h-[30px] text-[#0D0F18] text-[14px] font-semibold rounded-[30px] px-4"
            >
              <div className={`sub-button first-letter:uppercase`}>
                {"Join Airdrop"}
              </div>
            </button>
          )}
        </div>
      </div>
      <JoinProjectModal
        open={openModal}
        setOpen={setOpenModal}
        data={airdrop}
        message={airdrop_?.message}
        joined={airdrop_?.joined}
        onClick={() => joinAirdrop()}
        btn={btn}
      />
    </div>
  );
};
